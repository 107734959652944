<template>
    <div>
        <AdminTop :heading="courseId ? 'Redigera kurs' : 'Skapa kurs'" />

        <BaseForm
            v-if="course"
            :loading="loading"
            :submit-label="courseId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Namn"
                name="name"
            >
                <InputField
                    v-model="course.name"
                    name="name"
                    type="text"
                    required
                />
            </FormGroup>

            <FormGroup
                label="Kurskod"
                name="code"
            >
                <InputField
                    v-model="course.code"
                    name="code"
                    type="text"
                    required
                />
            </FormGroup>

            <FormGroup
                label="Färg"
                name="color"
            >
                <ColorPicker v-model="course.color" />
            </FormGroup>

            <FormGroup
                label="Ikon"
                name="icon"
            >
                <IconPicker
                    v-model="course.icon"
                    :icons="icons"
                />
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import InputField from '@/components/form/InputField';
import IconPicker from '@/components/form/IconPicker';
import ColorPicker from '@/components/form/ColorPicker';
import CourseService from '@/services/admin/CourseService';
import icons from '@/utils/courseIcons';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        InputField,
        IconPicker,
        ColorPicker
    },

    data: () => ({
        loading: false,
        course: null,
        icons
    }),

    computed: {
        courseId () {
            return this.$route.params.id;
        }
    },

    async created () {
        this.course = await this.getCourse();
    },

    methods: {
        async getCourse () {
            if (!this.courseId) {
                return {
                    name: null,
                    code: null,
                    icon: null,
                    color: '#88bcca'
                };
            }

            const res = await CourseService.get(this.$route.params.id);

            if (res.data && !res.data.color) {
                res.data.color = '#88bcca';
            }

            return res.data;
        },

        async handleSubmit () {
            this.loading = true;

            try {
                if (this.courseId) {
                    await this.update();
                } else {
                    await this.create();
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async create () {
            const res = await CourseService.create(this.course);

            if (res.status === 200 && res?.data?.course?.id) {
                this.$router.push(
                    `/admin/events/courses/${res.data.course.id}`
                );
            }
        },

        async update () {
            return await CourseService.update(this.courseId, this.course);
        }
    }
};
</script>
